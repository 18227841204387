.success,
.units {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.units {
  > section {
    padding: 0;
  }
}

.success {
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 2rem;

  > canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  > img {
    width: 25%;
  }
}

.modalAction {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;

  .modalButtons {
    display: flex;
    gap: 2rem;
  }
}
