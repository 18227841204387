@use '../../../styles/imports';

.section {
  display: grid;
  border-bottom: 1px solid var(--card-border-color);
  padding: 40px 0;
  grid-template-columns: 1fr;

  &:only-child {
    border-bottom: none;
  }

  > h2 {
    padding-bottom: 20px;
    font-size: 2rem;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.sticky {
    @include imports.media(L) {
      grid-template-columns: 15% 85%;

      > h2 {
        padding-bottom: 0;
        top: 40px;
        position: sticky;
        height: max-content;
      }
    }
  }
}

// print
@media print {
  .section {
    grid-template-columns: 1fr !important;
    padding: 20px 0 !important;

    > h2 {
      padding-bottom: 10px !important;
      top: 0 !important;
    }
  }
}
