@use '../../../styles/imports';

.loadingScreen {
  height: 100%;
  width: 100%;
  display: flex;
  z-index: 1;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.profile {
  height: 100%;
  width: 100%;
  display: flex;
  z-index: 1;
  overflow-y: auto;
  justify-content: center;

  //  TODO: smart center
  padding: 10vh 20px 20px;

  @include imports.customScrollbar;

  @include imports.media(S) {
    align-items: start;
    padding: 20px;
  }

  .profileContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    height: fit-content;
    gap: 20px;

    .studentInfo {
      display: grid;
      grid-row-gap: 10px;
      justify-items: center;

      .tabs {
        max-width: 100%;
        overflow: auto;
      }

      .contentContainer {
        width: 100%;
      }
    }
  }
}

.animation {
  position: absolute;
  max-width: 50%;
  bottom: 0;
  right: 0;
  line-height: 0;
}

.background {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: 0;
  background-image: url('../../../assets/images/Illustration.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
}

[role='tabpanel']:focus-visible {
  border-radius: 2px;
  outline: 2px solid var(--card-input-focused-border-color);
}
