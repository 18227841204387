.period {
  display: flex;
  flex-direction: column;
  gap: 20px;

  > h4 {
    font-weight: 400;
    margin: 0;
  }

  .listedBlocks {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 0;
    margin: 0;
  }

  .blocks {
    display: grid;
    gap: 10px;
    padding: 0;
    margin: 0;
  }
}

// print
@media print {
  .period {
    gap: 10px !important;

    .listedBlocks {
      row-gap: 5px !important;
    }

    .blocks {
      &.gridDisplay {
        grid-template-columns: repeat(4, 1fr) !important;
      }
    }
  }
}
