@use '../../../../styles/imports';

.embargo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  border-bottom: 1px solid var(--card-border-color);
  padding-bottom: 40px;

  > img {
    width: 100%;
    align-self: center;

    @include imports.media(L) {
      max-width: 50%;
    }

    @include imports.media(M) {
      max-width: 75%;
    }
  }
}
