.header {
  margin: auto;
  display: flex;
  gap: 20px;
  align-items: center;

  .details {
    p {
      color: var(--card-text-secondary-color);
    }
  }
}
