@use '../../../../styles/imports';

.avatarSelector {
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center center;

  .updating {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    color: var(--card-cta-bg-color);
  }

  .avatarOptions {
    width: 100%;
    display: grid;
    align-self: start;
    align-items: center;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 20px;

    @include imports.media(M) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include imports.media(S) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
