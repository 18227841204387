.quickLinks {
  .links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    &.S {
      grid-template-columns: 1fr;
    }

    // TODO: global link styles
    > a {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      color: var(--card-cta-bg-color);
      text-decoration: none;

      > * {
        flex: 0 0 auto;
      }
    }
  }
}

@media print {
  .quickLinks {
    display: none !important;
  }
}
