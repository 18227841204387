@use '../../../styles/imports';

.coursePlan {
  height: 100%;
  width: 100%;

  .printOnlyGlossary {
    display: none;
  }

  .navigation {
    display: flex;
    justify-content: space-between;
  }

  .planHeader {
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    @include imports.media(S) {
      flex-direction: column;
    }

    p {
      font-size: 2rem;
      font-weight: 500;
    }

    .actions {
      display: flex;
      gap: 2rem;
    }
  }
}

.loadingScreen {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

@media print {
  .navigation {
    display: none !important;
  }

  .planHeader {
    .actions {
      display: none !important;
    }
  }
}

@media print {
  .coursePlan {
    // temporarily added half a page of buffer to support varies browsers (different print margins and flex / grid height bug)
    padding-bottom: 421pt !important;

    .printOnlyGlossary {
      display: block !important;

      // temporary override for print
      > section {
        border-bottom: none !important;
      }
    }
  }
}
