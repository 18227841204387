.creditProgress {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-top: 1px solid var(--color-border);
  padding-top: 20px;

  > label {
    color: var(--card-text-secondary-color);
  }

  > p {
    font-weight: 500;
    margin: 0;
  }

  .disclaimer {
    padding-top: 10px;
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: var(--card-text-secondary-color);
  }
}

@media print {
  .creditProgress {
    gap: 5px;
  }
}
