.legendItem {
  display: flex;
  white-space: nowrap;
  justify-content: center;
  align-items: center;

  .blob {
    height: 12px;
    width: 2rem;
    margin: 0 8px 0 0;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px var(--card-border-color);

    &.required {
      border-radius: 0;
      height: 0;
      box-shadow: none;
      border-top: dashed 1px var(--card-text-secondary-color);
      z-index: 0;
    }
  }
}
