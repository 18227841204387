.card {
  width: 100%;
  background-color: var(--card-bg-color);
  border-radius: 16px;
  border: 1px solid var(--card-border-color);
  box-shadow: 0 1px 0 var(--card-border-color);
  padding: 30px;
  gap: 20px;
  display: grid;
}

// print
@media print {
  .card {
    padding: 0 !important;
    border: 0 !important;
    gap: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
}
