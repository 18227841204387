.intervalMarkers {
  width: 100%;
  display: flex;
  font-size: 12px;
  color: var(--card-text-color);
  margin-top: 8px;
  margin-bottom: 10px;

  .marker {
    width: 1px;
    display: flex;
    justify-content: center;
    position: absolute;

    > label {
      padding-top: 4px;

      &.up {
        top: -50px;
      }
    }

    &::before {
      content: '';
      width: 1px;
      height: 4px;
      background-color: var(--card-text-secondary-color);
      position: absolute;
      left: calc(50% - 0.5px);
      top: -4px;
    }

    &.labelled {
      &::before {
        height: 6px;
      }
    }
  }
}
