.courseDetails {
  display: grid;
  gap: 2rem;

  .item {
    display: flex;
    flex-direction: column;

    > label {
      color: var(--card-text-secondary-color);
      padding-bottom: 10px;
      white-space: nowrap;
    }

    > p {
      font-weight: 500;
      margin: 0;
    }
  }
}

// print
@media print {
  .courseDetails {
    display: flex !important;
    gap: 20px !important;

    .item {
      width: 100% !important;

      > label {
        padding-bottom: 5px !important;
      }
    }
  }
}
