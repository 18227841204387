@use '../../../../styles/imports';

.academicRecord {
  display: flex;
  flex-direction: column;

  .headerGroup {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;

    @include imports.media(S) {
      flex-direction: column;
      gap: 10px;
    }

    .header {
      display: flex;
      gap: 5px;
      align-items: center;

      > h2 {
        font-size: 2rem;
      }
    }

    .actionGroup {
      display: flex;
      gap: 10px;
      align-items: center;

      .action {
        border-right: 1px solid var(--card-border-color);
        padding-right: 10px;

        &:last-of-type {
          border-right: 0;
          padding-right: 0;
        }
      }
    }
  }

  .years {
    display: flex;
    flex-direction: column;
  }
}

// print
@media print {
  .academicRecord {
    .header {
      > button {
        display: none !important;
      }
    }

    .headerGroup {
      padding-top: 20px !important;

      .actionGroup {
        display: none !important;
      }
    }
  }
}
