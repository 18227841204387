.barItem {
  height: 100%;
  border-radius: 20px;

  .filled {
    border: 1px solid white;
    height: 100%;
    width: 100%;
    border-radius: 20px;
    animation: grow 0.75s ease-in-out forwards;

    @media (prefers-reduced-motion) {
      animation: grow 0s ease-in-out forwards;
    }
  }
}

@keyframes grow {
  0% {
    width: 0;
  }
}
