.guide {
  .status {
    padding: 20px 0;
    border-bottom: 1px solid var(--card-border-color);

    .statusContent {
      line-height: 2.4rem;
    }

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}

// print
@media print {
  .guide {
    display: grid !important;
    column-gap: 20px !important;
    grid-template-columns: repeat(2, 1fr) !important;

    .status {
      padding: 10px 0 !important;

      &:last-of-type {
        border-bottom: 1px solid var(--card-border-color) !important;
      }

      .statusContent {
        // temporary fix for external link print styling
        > a {
          font-size: 10pt !important;
          line-height: normal !important;

          svg {
            height: 1.4rem !important;
            width: 1.4rem !important;
          }
        }
      }
    }
  }
}
