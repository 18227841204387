.listBlock {
  display: grid;
  column-gap: 20px;

  &:focus-visible {
    outline: 2px solid var(--card-input-focused-border-color);
    border-radius: 4px;
    outline-offset: 4px;
  }

  .item {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }

  &.L {
    grid-template-columns: 1fr 3fr 2fr 1fr 1fr;
    grid-template-areas: 'Unitcode Unitname Status Grade Creditpoints';
  }

  &.M {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      'Unitcode Unitname Unitname'
      'Creditpoints Status Grade';
  }

  &.S {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'Unitcode Creditpoints'
      'Unitname Unitname'
      'Status Grade';
  }

  &.M,
  &.S {
    padding: 10px 0 20px;
    row-gap: 20px;
    border-bottom: solid 1px var(--card-border-color);

    .item {
      gap: 10px;
    }

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}

// print
@media print {
  .listBlock {
    gap: 0 10px !important;
    grid-template-columns: 1fr 3fr 2fr 1fr 1fr !important;
    border-bottom: none !important;
    padding: 0 !important;
    grid-template-areas: 'Unitcode Unitname Status Grade Creditpoints' !important;

    .item {
      gap: 5px !important;
    }
  }
}
