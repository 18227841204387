.pageContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;

  .contentContainer {
    height: 100%;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
}

// print
@media print {
  .pageContainer {
    .contentContainer {
      padding: 0 20px !important;
    }
  }
}
