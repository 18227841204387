.progressBar {
  .bar {
    margin: 20px 0 40px;
    height: 18px;
    border-radius: 20px;
    border: solid 1px var(--card-border-color);

    .barItems {
      display: flex;
      height: 100%;
    }

    .requiredCPMarker {
      position: absolute;
      display: flex;
      top: calc(-150% + 8px);
      height: 300%;
      z-index: 3;
      border-left: dashed 1px var(--card-text-secondary-color);
    }
  }
}

@media print {
  .progressBar {
    .bar {
      margin: 10px 0 40px !important;
    }
  }
}
