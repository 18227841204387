.infoItem {
  width: 100%;
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-column-gap: 20px;
  align-items: center;
  border-bottom: solid 1px var(--card-border-color);
  padding-bottom: 20px;

  &:last-of-type {
    border: none;
    padding-bottom: 0;
  }

  .content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;

    p {
      // TODO: email address need special word break treatment
      overflow: hidden;
      text-overflow: ellipsis;
    }

    label {
      padding-bottom: 10px;
      color: var(--card-text-secondary-color);
    }
  }
}
