@use '../../../../../styles/imports';

.userAvatar {
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: var(--card-bg-color);
  border: 1px solid var(--card-border-color);

  > img {
    border: 1px solid var(--card-border-color);
    border-radius: 100%;
    object-fit: cover;
    height: 100px;
    width: 100px;
  }

  .editButtonWrapper {
    position: absolute;
    left: calc(100% - 32px);
    top: calc(100% - 32px);
    border-radius: 100%;
    box-shadow: imports.$shadow-sm;
    background-color: var(--card-bg-color);
    border: 1px solid var(--card-border-color);
    padding: 4px;
    line-height: 0;

    &:hover {
      cursor: pointer;
    }
  }

  .editButton {
    padding: 0;
  }
}

.modalAction {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
