.blockCreditPointsTag {
  width: fit-content;
  height: fit-content;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--card-cta-bg-color);
  background-color: #e8eef4;
  border: 1px solid #c5d2df;
  border-radius: 50px;
  padding: 2px 6px;
}

// print
@media print {
  .blockCreditPointsTag {
    // temporary fixed height to fight weird page break behaviour
    height: 2.4rem !important;
  }
}
