.courseHeader {
  .header {
    p {
      font-size: 2rem;
      font-weight: 500;
    }
  }
}

// print
@media print {
  .courseHeader {
    .header {
      p {
        font-size: 12pt;
      }
    }
  }
}
