.pronoun {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  label {
    color: var(--card-text-secondary-color);
  }
}
