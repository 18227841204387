.blockLabel {
  max-height: 1.6rem;
  line-height: 1.6rem;
  display: flex;
  gap: 4px;
  font-weight: 400;
  align-items: center;
  color: var(--card-text-secondary-color);
}

.infoButton {
  padding: 0;
}

// print
@media print {
  .blockLabel {
    > button {
      display: none !important;
    }

    &.hiddenInPrint {
      display: none !important;
    }
  }
}
