.avatar {
  width: 100%;
  border-radius: 100%;
  background-color: var(--card-bg-color);
  border: 1px solid var(--card-border-color);
  padding: 0;

  .imageContainer {
    border: none;
    border-radius: 100%;
    aspect-ratio: 1 / 1;
    overflow: hidden;

    > img {
      border: 1px solid var(--card-border-color);
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &.selected {
    border: 4px solid var(--card-cta-bg-color);
  }

  &:focus-visible {
    outline: 2px solid var(--card-input-focused-border-color);
    outline-offset: 4px;
  }

  .selectedIndicator {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 100%;
    top: 0;
    right: 0;
    z-index: 2;
    border: 2px solid var(--card-bg-color);
    background-color: var(--card-cta-bg-color);
    width: fit-content;
    height: fit-content;
  }
}
