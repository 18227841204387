.coursePlanning {
  width: 100%;
  gap: 4rem;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--canvas-bg-color);

  > h1 {
    text-align: center;
  }

  .intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    > p {
      text-align: center;
      line-height: 1.5;
      max-width: 72ch;
    }
  }
}

.loadingScreen {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
