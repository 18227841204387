@use '../../../../styles/imports';

.coursePlanPreview {
  width: 100%;
  max-width: 80rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--card-border-color);
  gap: 2rem;

  > a {
    width: 100%;
    padding: 20px;
    transition: all ease-in-out 0.3s;
    background-color: var(--card-bg-color);
    border: 1px solid var(--card-border-color);
    border-bottom: 2px solid var(--card-border-color);
    border-radius: var(--card-border-radius);
    color: var(--card-text-color);

    &:hover {
      cursor: pointer;
      box-shadow: imports.$shadow-lg;
      transform: translateY(-10px);
    }

    &:focus-visible {
      outline: 2px solid var(--card-input-focused-border-color);
      outline-offset: -2px;
      cursor: pointer;
      box-shadow: imports.$shadow-lg;
      transform: translateY(-10px);
    }

    .previewCard {
      width: 100%;
      height: 15rem;
      display: flex;
      gap: 2rem;

      .snapshot {
        width: 100%;
        background-color: #a1b6ca29;
        border: 1px solid var(--card-border-color);
        border-radius: 12px;
        overflow: hidden;
        padding: 2rem;

        &::before {
          content: '';
          top: 0;
          left: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          box-shadow: 0 -20px 40px 0 rgb(51 59 80 / 8.2%) inset;
          z-index: 2;
        }

        > section {
          padding: 0;
          transform: scale(0.7);
          transform-origin: top left;
        }
      }

      .details {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        > div {
          p {
            font-weight: 500;
          }
        }
      }
    }
  }
}
