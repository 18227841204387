.courseProgress {
  height: 100%;

  .printOnlyGlossary {
    display: none;
  }

  .loadingScreen {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
}

// print
@media print {
  .courseProgress {
    // temporarily added half a page of buffer to support varies browsers (different print margins and flex / grid height bug)
    padding-bottom: 421pt !important;

    .printOnlyGlossary {
      display: block !important;

      // temporary override for print
      > section {
        border-bottom: none !important;
      }
    }
  }
}
