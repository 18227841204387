@use '~@monash/portal-react/dist/styles/color.module';

:root {
  --color-enrolment-status-enrolled: #{color.$color-green-700};
  --color-enrolment-status-completed: #{color.$color-blue-700};
  --color-enrolment-status-duplicate: #{color.$color-blue-700};
  --color-enrolment-status-discontinued: #{color.$color-grey-500};
  --color-enrolment-status-unconfirm: #{color.$color-grey-500};
  --color-enrolment-status-planned: #{color.$color-grey-500};
  --color-enrolment-status-invalid: #{color.$color-red-500};
  --color-prior-study: #{color.$color-purple-800};
}
