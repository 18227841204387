.inlineForm {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
