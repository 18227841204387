.blockStatusTag {
  line-height: 2.4rem;

  .keyBlob {
    display: inline-block;
    height: 10px;
    width: 2rem;
    margin: 0 8px 0 0;
    border-radius: 8px;
  }

  .status {
    font-weight: 500;
  }
}

// print
@media print {
  .blockStatusTag {
    // temporary fixed height to fight weird page break behaviour
    height: 2.4rem !important;

    .keyBlob {
      display: none !important;
    }
  }
}
