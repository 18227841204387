.blockUnitCode {
  line-height: 2.4rem;
  font-weight: 500;

  > abbr {
    text-decoration: none;
  }
}

// print
@media print {
  .blockUnitCode {
    // temporary fixed height to fight weird page break behaviour
    height: 2.4rem !important;
  }
}
