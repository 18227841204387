.gridBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  background-color: var(--card-bg-color);
  border: 1px solid var(--card-border-color);
  box-shadow: 0 1px 0 var(--card-border-color);

  .top {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px;
    flex-grow: 1;
    gap: 8px;

    .title {
      height: 100%;
      flex-grow: 1;
      line-height: 1.25;
      padding-bottom: 8px;
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--card-border-color);
    padding: 8px 16px;
    font-weight: 500;
  }
}

// print
@media print {
  .gridBlock {
    border-radius: 8px !important;

    .top {
      padding: 8px !important;
      gap: 4px !important;

      .title {
        padding-bottom: 4px !important;
      }
    }

    .bottom {
      padding: 4px 8px !important;
    }
  }
}
