.courseSelector {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
}

// print
@media print {
  .courseSelector {
    button {
      background-color: transparent !important;
      padding: 0 !important;
      min-height: 0 !important;
      border: none !important;
    }

    svg {
      display: none !important;
    }
  }
}
